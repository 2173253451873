import { createElement, useContext, useLayoutEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';

import getRedirectPath from '../lib/getRedirectPath';

import { LocalizationContext } from '../contexts/LocalizationContext';

const LocalizedLinkContainer = ({ children, tagName = 'span', ...props }) => {
  const ref = useRef(null);
  const { redirectMap } = useContext(LocalizationContext);

  useLayoutEffect(() => {
    const { current: container } = ref;
    if (container) {
      [].forEach.call(container.querySelectorAll('a[href]'), anchor => {
        anchor.href = getRedirectPath(anchor.getAttribute('href'), redirectMap);
      });
    }
  }, [redirectMap]);

  return createElement(tagName, { ...props, ref }, children);
};

LocalizedLinkContainer.propTypes = {
  tagName: PropTypes.elementType,
  children: PropTypes.node,
};

LocalizedLinkContainer.defaultProps = {
  tagName: 'span',
  children: null,
};

export default LocalizedLinkContainer;
