import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'gatsby-image';

import { themeCssVar as globalTheme, UiEngineProvider } from '@tinderapp/ui-react';

// NOTE: Stylesheet imports are injected into ALL PAGES regardless of whether the layout uses them.
import '@tinderapp/ui-core/fonts/face.css';
import '@tinderapp/ui-core/themes/default.css';

import { centerContainerMaxWidth, minWidthMedium } from '../lib/styles';

import GlobalStyle from './GlobalStyle';
import Main from './Main';

const NativeImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const FluidImage = styled(Image)``;

const HeaderWrapper = styled.div`
  background-color: ${globalTheme.color.backgroundSecondary};

  ${NativeImage},
  ${FluidImage} {
    max-height: 100vh;
  }

  @media ${minWidthMedium} {
    ${NativeImage},
    ${FluidImage} {
      height: 400px;
    }
  }
`;

const MainWrapper = styled.div`
  flex-grow: 1;

  ${Main} {
    ${centerContainerMaxWidth}
  }
`;

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Layout = ({ children, hero, locale }) => (
  <UiEngineProvider>
    <GlobalStyle locale={locale} />
    <LayoutWrapper>
      {hero ? (
        <HeaderWrapper>
          {typeof hero === 'string' ? (
            <NativeImage src={hero} alt="" />
          ) : (
            <FluidImage fluid={hero} />
          )}
        </HeaderWrapper>
      ) : null}
      <MainWrapper>
        <Main>{children}</Main>
      </MainWrapper>
    </LayoutWrapper>
  </UiEngineProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hero: PropTypes.oneOfType([PropTypes.string, Image.propTypes.fluid]),
  locale: PropTypes.string,
  messages: PropTypes.shape({}),
};

Layout.defaultProps = {
  hero: undefined,
  locale: undefined,
  messages: undefined,
};

export default Layout;
